<template>
  <div class="sbox"  v-loading.lock="fullscreenLoading">
    <el-row v-if=" illList.length != 0 && infoList.length != 0">
      <el-row class="second" style="margin-bottom:20px">
        <el-col :span="12" v-for="project in illList" :key="project.pid" :offset="1" style="margin-top:20px" class="ql introducehover"  @click.native="itemClick(project.infoId)">
            <introduce-disease :introduce-item="project" class="now" :search_text="search_text" ></introduce-disease>
        </el-col>
      </el-row>
      <el-row>
      <el-col v-for="(item, index) in infoList" :key="index">
        <!-- <el-row class="second" v-if="item.infoType === ''">
          <el-col :span="12" v-for="project in item" :key="project.pid" :offset="1" style="margin-top:20px" class="ql introducehover">
              <introduce-disease :introduce-item="project" class="now"></introduce-disease>
          </el-col>
        </el-row> -->
        <el-row class="second" v-if="item.infoType === 'qa'" style=" margin-left: 50px">
          <el-col :span="24">
              <long-question-answertest :question-item="item" class="now ql" :search_text="search_text"></long-question-answertest>
          </el-col>
        </el-row>
        <el-row v-else>
          <el-col :span="24"  :offset="1" class="elhover">
            <long-videos-listtest :videos="item" class="now ql" :search_text="search_text"></long-videos-listtest>
          </el-col>
        </el-row>
      </el-col>
      </el-row>
      <el-row>
        <el-pagination
        style="text-align: center; margin: 20px 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="10"
          :current-page="currentPage"
          layout="prev, pager, next, jumper"
          :total="100">
        </el-pagination>
      </el-row>
    </el-row>
    <el-row v-else>
          <el-empty description="" style="margin-top:100px; width: 100px;
          text-align: center; margin:0 auto"></el-empty>
    </el-row>
  </div>
</template>

<script>
import LongVideosListtest from 'components/content/videos/LongVideosListtest'
import introduceDisease from 'components/content/introduce/introduceDisease'
// import LongQuestionAnswerList from 'components/content/questionanswer/LongQuestionAnswerList'
import LongQuestionAnswertest from 'components/content/questionanswer/LongQuestionAnswertest'
import { Encrypt, Decrypt } from '@/util/EncryptionToDecrypt'
import { getsearchdetailList } from 'network/getData'
export default {
  name: 'Search',
  components: {
    LongVideosListtest,
    LongQuestionAnswertest,
    introduceDisease
  },
  data () {
    return {
      search_text: '',
      infoList: [],
      illList: [],
      currentPage: 1,
      fullscreenLoading: false
    }
  },
  // metaInfo () {
  //   return {
  //     meta: [
  //       { name: 'keywords', content: '科普视频,专家文章,专家音频,名医问答,查疾病,瑞尔安心、瑞尔良医、瑞尔良医app、瑞尔安心官网、瑞尔视景、瑞尔视景科技有限公司、单道动态心电记录仪、7天可穿戴心电监测设备、智能心电监测设备' },
  //       { name: 'description', content: '瑞尔安心在线平台,专家,疾病,科室等详细信息,由专家生产的科普视频,文章,音频等内容,瑞尔安心平台向全民提供“科普视频+语音问答+图文咨询+文章+大数据分析”多位一体的精准健康科普知识，满足大众多样化、多场景应用的健康科普需求。致力于通过互联网技术推动医生共享、知识共享、服务共享、经济共享，放大医生价值和健康科普内容价值，用通俗易懂的方式以及直白语言，制作出大众听得懂、记得住、学得会、用得上的健康科普知识，切实提升公众健康素养，让更多人能够健康地享受美好生活，助力全民健康。研发出多款医疗器械，实现了心脑血管病患者、高危人群得日常疾病筛查和健康管理，并配套了全程专业的健康管理服务，在国内医疗产品行业中具有很高的地位和影响。公司主要从事公司下设医学研发中心、软件开发中心、生产制造中心、营销中心、医学服务中心。' }
  //     ]
  //   }
  // },
  watch: {
    $route: function (newVal) {
      // console.log('newVal', newVal)
      this.getsearchdetailListdata(10, 1, Decrypt(newVal.query.query))
      this.search_text = Decrypt(newVal.query.query)
      this.currentPage = 1
    },
    immediate: true
  },
  methods: {
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.getsearchdetailListdata(10, this.currentPage, Encrypt(this.$route.query.query))
    },
    itemClick (e) {
      // sessionStorage.setItem('nav', '1')
      this.$router.push({ path: 'disease/diseasedetail', query: { i: e } })
    },
    async getsearchdetailListdata (rows, page, searchInfo) {
      try {
        this.fullscreenLoading = true
        const result = await getsearchdetailList(rows, page, searchInfo)
        if (result === '') {
          this.getsearchdetailListdata(10, 1, Decrypt(this.$route.query.query))
        }
        this.infoList = result.data.data.infoList
        this.illList = result.data.data.illnessList
        this.fullscreenLoading = false
        // console.log(result.data.data)
      } catch (error) {
        // console.log(error)
      }
    }
  },
  mounted () {
    this.search_text = Decrypt(this.$route.query.query)
    this.fullscreenLoading = true
    this.getsearchdetailListdata(10, 1, Decrypt(this.$route.query.query))
  }
}
</script>

<style scoped>
.sbox{
  /* position: relative; */
  width: 1200px;
  background: #FFFFFF;
  margin: 0 auto;
}
.frist{
  /* position: relative; */
  /* left: -120px; */
}
.second{
  /* position: relative; */
  /* top: 20px; */
  /* left: 60px; */
}
.elhover :hover{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.now :hover{
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
.ql{
  /* width: 1038px; */
}
.introducehover :hover{
  box-shadow: 0px 2px 20px rgba(189, 188, 188, 0.06), 0px 4px 6px rgba(139, 137, 137, 0.1);
}
.introducehover{
    border: 1px solid #F5F5F5;
    border-radius: 4px;
    width: 950px;
}
::v-deep .el-loading-spinner{
  /* top: 25%; */
}
</style>
