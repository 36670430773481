<template>
  <div class="zbox">
    <el-row>
      <el-col :span="24" >
        <div class="asdef">
          <div style="margin-right:8px"><img src="~assets/A.svg" alt=""></div><div class="title"><span v-html="illness"></span></div>
        </div>
      </el-col>
      <el-col :span="24" class="btn">
        <span v-for="(item, index) in btn" :key="index" style="margin-right: 20px">
          <el-tag type="info">{{item}}</el-tag>
        </span>
      </el-col>
      <el-col :span="24">
        <p class="introduce" v-html="description"></p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import HighLight from 'util/highLight'
export default {
  data () {
    return {
      btn: ['疾病首页', '相关视频', '相关语音', '相关文章', '相关问答']
    }
  },
  props: {
    introduceItem: {
      type: Object,
      default () {
        return {}
      }
    },
    search_text: {
      type: String
    }
  },
  computed: {
    description () {
      // let description = this.introduceItem.description
      return HighLight('description', this.introduceItem.description, this.search_text)
    },
    illness () {
      let illness = this.introduceItem.illness
      if (!illness) {
        return ''
      }
      if (this.search_text && this.search_text.length > 0) {
        // 匹配关键字正则
        const replaceReg = new RegExp(this.search_text, 'g')
        // 高亮替换v-html值
        const replaceString = '<span class="search-text" style= "color:#6F8CF3">' + this.search_text + '</span>' + '<span>介绍、</span>' + '<span class="search-text" style= "color:#6F8CF3">' + this.search_text + '</span>' + '<span>病因、</span>' + '<span class="search-text" style= "color:#6F8CF3">' + this.search_text + '</span>' + '<span>治疗方案......</span>'
        // 开始替换
        illness = illness.replace(replaceReg, replaceString)
      }
      return illness
    }
  },
  methods: {
    // itemClick (e) {
    //   this.$router.push({ path: 'diseasedetail', query: { i: e } })
    // }
  }
}
</script>

<style scoped>
.zbox{
  padding: 15px 30px 0 30px;
  width: 890px;
}
.title{
  font-size: 16px;
  line-height: 24px;
  /* margin-bottom: 8px; */
}
.el-tag{
  /* display:flex; */
  /* align-items:center; */
  padding: 0 28px;
  line-height: 30px;
  font-size: 14px;
  cursor: pointer;
}
.btn{
  margin: 8px 0;
}
.introduce{
  /* width: 1038px; */
  font-size: 14px;
  color: #8C8C8C;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.asdef{
  display: flex;
  align-items: center;
}
.search-text{
  color: #6F8CF3 !important;
}
</style>
